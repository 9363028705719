(() => {
  const handleMenu = () => {
    let header: HTMLElement,
      stickyTriggerVal = 40,
      brandMenuTrigger: HTMLAnchorElement,
      brandMenuTriggerItem: HTMLLIElement,
      brandMenuDropdown: HTMLElement,
      parentItem: HTMLElement,
      closeMenuButton: HTMLElement;

    const initVariables = () => {
      header = document.querySelector('header.header') as HTMLElement;
      brandMenuTrigger = document.querySelector(
        '.header__eyebrow-items a[href="#brand-menu"]'
      ) as HTMLAnchorElement;
      brandMenuTriggerItem = brandMenuTrigger?.closest('li') as HTMLLIElement;
      brandMenuDropdown = document.querySelector(
        '.header__brand-menu-dropdown'
      ) as HTMLElement;
      parentItem = document.querySelector(
        '.emu-navigation__item-parent'
      ) as HTMLElement;
      closeMenuButton = document.querySelector(
        '.header__menu-mobile-trigger'
      ) as HTMLElement;
    };

    const appendEvents = () => {
      // when window is scrolled, if the scrollY is greater than stickyTriggerVal, make the header sticky
      window.addEventListener('scroll', () => {
        header?.classList.toggle(
          'header--sticky',
          window.scrollY > stickyTriggerVal
        );
      });

      // open brand menu on click and change background of list item
      brandMenuTrigger?.addEventListener('click', e => {
        e.preventDefault();
        brandMenuDropdown?.classList.toggle(
          'header__brand-menu-dropdown--open'
        );
        brandMenuTriggerItem?.classList.toggle('item-active');
      });

      // when brand menu close icon is clicked, close the dropdown
      window.Bus.on('emu-button:click', ({ id }) => {
        if (id === 'header__brand-menu-close-btn') {
          brandMenuDropdown?.classList.remove(
            'header__brand-menu-dropdown--open'
          );
          brandMenuTriggerItem?.classList.remove('item-active');
        }
      });

      // Close the menu when a sublist item is clicked.
      if (parentItem && closeMenuButton) {
        parentItem.addEventListener('click', event => {
          const target = event.target as Element;
          if (target.closest('li.emu-navigation__item')) {
            closeMenuButton.click();
          }
        });
      }
    };

    initVariables();
    appendEvents();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleMenu);
  } else {
    handleMenu();
  }
})();
