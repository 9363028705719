(() => {
  const handleResultsZoom = $el => {
    let $imgs: NodeListOf<HTMLImageElement>;
    let $imgContainers: NodeListOf<HTMLElement>;
    let $customCursorText: HTMLElement;

    const initVariables = () => {
      $imgs = $el?.querySelectorAll('img') as NodeListOf<HTMLImageElement>;
      $imgContainers = $el?.querySelectorAll(
        '.emu-image'
      ) as NodeListOf<HTMLElement>;
      $customCursorText = $el
        .closest('.custom-cursor__container')
        ?.querySelector('.custom-cursor__text');
    };

    const mouseMove = ($el, event) => {
      if ($el.classList.contains('zoom-enabled')) {
        $imgs.forEach($img => {
          $img.style.top = $img.clientHeight / 2 - event.offsetY + 'px';
          $img.style.left = $img.clientWidth / 2 - event.offsetX + 'px';
        });
      }
    };

    const resetZoomAndPan = () => {
      $imgs.forEach($imgEl => {
        $imgEl.classList.add('animated');
        $imgEl.style.width = '';
        $imgEl.style.left = '';
        $imgEl.style.top = '';
      });

      $imgContainers.forEach($container => {
        $container.classList.remove('zoom-enabled');
      });

      // timer so that it will be done in the macro queue, and does not collide with other events
      setTimeout(() => {
        $customCursorText?.classList?.remove(
          'custom-cursor__text--toggle-text'
        );
      });
    };

    const toggleZoom = ($el: HTMLElement, event) => {
      const $img = $el.querySelector('img') as HTMLImageElement;

      if ($el.classList.contains('zoom-enabled') !== true) {
        const rect = $img.getBoundingClientRect();
        const xPos = event.clientX - rect.left;
        const yPos = event.clientY - rect.top;

        const newWidth = $img.naturalWidth + 200; // 200 is the value from live
        const newHeight = (newWidth * $img.naturalHeight) / $img.naturalWidth;
        const newXPos = (newWidth * xPos) / rect.width;
        const newYPos = (newHeight * yPos) / rect.height;

        $imgs.forEach($imgEl => {
          // animation should happen only when entering and exiting the zoom and pan functionality.
          // animation should not apply while pan happens
          $imgEl.classList.add('animated');
          $imgEl.addEventListener(
            'transitionend',
            () => {
              $imgEl.classList.remove('animated');
            },
            {
              once: true,
            }
          );

          $imgEl.style.width = newWidth + 'px';
          $imgEl.style.left = xPos - newXPos + newWidth / 2 + 'px';
          $imgEl.style.top = yPos - newYPos + newHeight / 2 + 'px';
        });

        $imgContainers.forEach($container => {
          $container.classList.add('zoom-enabled');
        });
      } else {
        resetZoomAndPan();
      }
    };

    const appendEvents = () => {
      // when image container is clicked, zoom the image
      $imgContainers.forEach($el => {
        $el.addEventListener('click', e => {
          toggleZoom($el, e);
        });
      });

      // when image container is hovered, pan the image
      $imgContainers.forEach($el => {
        $el.addEventListener('mousemove', e => {
          mouseMove($el, e);
        });
      });

      // when close button is clicked, reset the zoom and pan
      window.Bus.on('emu-button:click', ({ id }) => {
        if (id === 'results-close-btn') {
          resetZoomAndPan();
        }
      });

      // when tab is changed, reset the zoom and pan
      window.Bus.on('emu-tabs:tabClick', () => {
        resetZoomAndPan();
      });

      // when accordion is toggled, reset the zoom and pan
      window.Bus.on('emu-accordion:toggle', ({ id }) => {
        if (id === 'results-mobile-accordion') {
          resetZoomAndPan();
        }
      });
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const $resultsImgContainer = document.querySelectorAll(
      '#results .results__banner-tab-content'
    );
    if ($resultsImgContainer) {
      setTimeout(() => {
        $resultsImgContainer.forEach($el => {
          handleResultsZoom($el);
        });
      }, 400);
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
