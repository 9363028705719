(() => {
  const handleFaq = $el => {
    let $faqAccordion: HTMLElement;
    let $faqItems: NodeListOf<HTMLElement>;
    let $showMoreBtn: HTMLButtonElement;
    let $showLessBtn: HTMLButtonElement;
    let initialCount = 5;
    let stepCount = 5;
    let totalCount: number;
    let visibleCount = 0;

    const initVariables = () => {
      $faqAccordion = $el?.querySelector('.aaaem-accordion');
      $faqItems = $faqAccordion?.querySelectorAll('.aaaem-accordion__item');
      $showMoreBtn = $el?.querySelector(
        '.about__faq-cta--show-more'
      ) as HTMLButtonElement;
      $showLessBtn = $el?.querySelector(
        '.about__faq-cta--show-less'
      ) as HTMLButtonElement;
      totalCount = $faqItems?.length;
    };

    const showContent = (count = initialCount) => {
      // hiding the accordion items
      $faqItems.forEach(($el, i) => {
        $el.classList.toggle('u-hide', i >= count);
      });

      visibleCount = count;

      // hide/show the buttons according to the visible content
      if (visibleCount <= totalCount) {
        $showMoreBtn.classList.remove('u-hide');
        $showLessBtn.classList.add('u-hide');
      } else {
        $showMoreBtn.classList.add('u-hide');
        $showLessBtn.classList.remove('u-hide');
      }
    };

    const appendEvents = () => {
      window.Bus.on('emu-button:click', ({ id }) => {
        if (id === 'read-more-btn') {
          showContent(visibleCount + stepCount);
        }

        if (id === 'show-less-btn') {
          showContent(initialCount);
          const faqPosition = $el?.getBoundingClientRect().top;

          if (faqPosition) {
            const offsetPosition = faqPosition + window.scrollY - 65;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }
      });
    };

    initVariables();
    appendEvents();
    showContent();
  };

  const init = () => {
    const $faq = document.getElementById('about-faq');
    if ($faq) {
      handleFaq($faq);
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
