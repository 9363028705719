// This file adds a tooltip to the custom cursor and manages the position of the tooltip content
// To achieve this, add a container with class custom-cursor__container. This is a container that contains the element which should have a custom cursor, and the tooltip text
// The element should have a class custom-container__element. In addition, adding custom-container__element--crosshair class will give green crosshair. Adding custom-container__element--crosshair-white will give white cross hair
// The tooltip text should have a class custom-container__text
// if the tooltip text has ul li. First li will be shown by default and clicking on the custom-container__element will toggle show the second li element

(() => {
  const addCustomCursor = ($el: HTMLElement) => {
    let $customCursorContainer: HTMLElement;
    let $customCursorEl: HTMLElement;

    const initVariables = () => {
      $customCursorContainer = $el?.closest(
        '.custom-cursor__container'
      ) as HTMLElement;
      $customCursorEl = $customCursorContainer?.querySelector(
        '.custom-cursor__element'
      ) as HTMLElement;
    };

    const appendEvents = () => {
      // adding event to move the tooltip text alongside the default cursor point
      $customCursorEl?.addEventListener('mousemove', event => {
        $el.style.left = event.clientX + 'px';
        $el.style.top = event.clientY + 'px';
      });

      // making the tooltip text visible when mouse leaves the tooltip element
      $customCursorEl?.addEventListener('mouseover', () => {
        $el.classList.add('custom-cursor__text--show');
      });

      // making the tooltip text hidden when mouse leaves the tooltip element
      $customCursorEl?.addEventListener('mouseleave', () => {
        $el.classList.remove('custom-cursor__text--show');
      });

      $customCursorEl?.addEventListener('click', () => {
        $el.classList.toggle('custom-cursor__text--toggle-text');
      });
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const $customCursorText = document.querySelectorAll(
      '.custom-cursor__text'
    ) as NodeListOf<HTMLElement>;
    $customCursorText.forEach($el => {
      addCustomCursor($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
