// For carousel which has additional functionality where anchors should trigger slide change, this JS file provides code for the same
// For this to work, a container wrapping carousel and the text component are needed
// Container should have a class anchored-carousel__container. The carousel should have a class anchored-carousel__main and the text component which has the anchors should have the class anchored-carousel__menu
// Carousel slides and the anchors in the text component are one to one mapped in terms of the index of them
(() => {
  const initAnchoredCarousel = ($el: HTMLElement) => {
    let $triggersContainer: HTMLElement;
    let $triggers: NodeListOf<HTMLAnchorElement>;
    let $triggerWrappers: NodeListOf<HTMLLIElement>;
    let $carousel: HTMLElement;
    let $carouselItems: NodeListOf<HTMLElement>;
    let carouselId: string;
    let carouselInst: any;
    let isAutoPlayed: boolean;
    let winWidth = window.innerWidth;

    // updates the active slide class.
    // The default tns class given by tns slider is not enough for cases where there are more slides in the viewport
    const setActiveSlideClass = (index?) => {
      if ($carouselItems?.length) {
        try {
          const curSlide =
            $carouselItems[index || carouselInst.getInfo().index];
          $carouselItems?.forEach($el => {
            $el.classList.remove('slide-active');
          });
          curSlide.classList.add('slide-active');
        } catch (e) {
          console.warn(e);
          $carouselItems?.forEach($el => {
            $el.classList.remove('slide-active');
          });
        }
      }
    };

    // when carousel index is changed, update the active anchor, and also update the active slide
    const carouselIndexChanged = slideIndex => {
      $el?.querySelector('.item-active')?.classList.remove('item-active');
      $triggerWrappers[slideIndex]?.classList.add('item-active');
      setActiveSlideClass();
      if (isAutoPlayed) {
        carouselInst?.play?.();
      }
    };

    const appendEvents = () => {
      // when anchors are clicked, move the carousel to the corresponding slide
      $triggers.forEach(($anchor, i) => {
        $anchor?.addEventListener('click', e => {
          e.preventDefault();
          carouselInst?.goTo?.(i);
        });
      });

      carouselInst?.events.on('indexChanged', info => {
        carouselIndexChanged(info.displayIndex - 1);
      });

      // this is very specific to results page carousel
      if ($carousel.id === 'results__weekly-treatment-sec-carousel') {
        $carouselItems?.forEach(($el, i) => {
          const index = $el.getAttribute('aria-label');
          $el.addEventListener('click', () => {
            if (winWidth < 768) {
              carouselInst?.goTo?.(index);
            } else {
              carouselIndexChanged(index);
              setActiveSlideClass(i);
            }
          });
        });

        // when browser is resized, taking the user to the first carousel item
        window.addEventListener('resize', () => {
          const curWidth = window.innerWidth;
          if (curWidth !== winWidth) {
            winWidth = curWidth;
            carouselInst?.goTo?.(0);
          }
        });
      }
    };

    const initVariables = () => {
      $triggersContainer = $el.querySelector(
        '.anchored-carousel__menu'
      ) as HTMLElement;
      $triggerWrappers = $triggersContainer.querySelectorAll(
        'li'
      ) as NodeListOf<HTMLLIElement>;
      $triggers = $triggersContainer.querySelectorAll(
        'a'
      ) as NodeListOf<HTMLAnchorElement>;
      $carousel = $el.querySelector('.anchored-carousel__main') as HTMLElement;
      carouselId = $carousel?.id;
      if (carouselId) {
        carouselInst = window._tnsInstances?.[carouselId];
      }
      isAutoPlayed = $carousel?.getAttribute('data-autoplay') === 'true';
      try {
        //@ts-ignore
        $carouselItems = Array.from(carouselInst?.getInfo?.()?.slideItems);
      } catch (e) {
        $carouselItems = $carousel.querySelectorAll('.emu-carousel__item');
      }
    };

    if ($el) {
      initVariables();
      appendEvents();

      //@ts-ignore
      $triggerWrappers?.[0]?.classList.add('item-active');
      setActiveSlideClass();
    }
  };

  const init = () => {
    const $anchoredCarouselContainers = document.querySelectorAll(
      '.anchored-carousel__container'
    ) as NodeListOf<HTMLElement>;
    $anchoredCarouselContainers.forEach($el => {
      initAnchoredCarousel($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
