// This file adds events on elements that should trigger a modal when clicked
// This code is needed because AAAEM provides modal open functionality only on anchors from RTE and for buttons
// This code is for elements that are not from RTE and not buttons
// For this to work, the element to which the click event must be appended should have a class `u-open-modal-[MODAL_ID]` where MODAL_DI is the id of the modal
// There should be a button on the page, that opens the modal and the buttons ID must be `[MODAL_ID]-modal-trigger`
(() => {
  const appendOpenModal = ($el: HTMLElement) => {
    let openModalSelector = 'u-open-modal-';
    let openModalSuffix = '-modal-trigger';
    let modalId: string;
    let $modalTrigger: HTMLButtonElement;

    const initVariables = () => {
      try {
        const modalUtilClass = Array.from($el.classList).filter(
          e => e.indexOf(openModalSelector) >= 0
        )[0];
        if (modalUtilClass) {
          modalId = modalUtilClass.replace(openModalSelector, '');
          if (modalId) {
            $modalTrigger = document.querySelector(
              `#${modalId}${openModalSuffix}`
            ) as HTMLButtonElement;
          }
        }
      } catch (e) {
        console.warn(e);
      }
    };

    const appendEvents = () => {
      if ($modalTrigger) {
        // appending event on the element to open the modal
        $el.addEventListener('click', () => {
          $modalTrigger?.click?.();
        });
      }
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const $openModalEls = document.querySelectorAll(
      '[class*="u-open-modal-"]'
    ) as NodeListOf<HTMLElement>;

    $openModalEls.forEach($el => {
      appendOpenModal($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
