(() => {
  const handleResultsBanner = $el => {
    let $viewGallery: HTMLElement;
    let $resultsCarouselWrapper: HTMLElement;
    let $resultsContent: HTMLElement;
    let $resultsFootnote: HTMLElement;
    let $carouselItemList: NodeListOf<HTMLElement>;
    let $resultsAccordion: HTMLElement;
    let $resultsDesktopTabsAnchor: NodeListOf<HTMLElement>;

    const initVariables = () => {
      $viewGallery = $el?.querySelector('.results__banner-view-gallery');
      $resultsCarouselWrapper = $el?.querySelector(
        '.results__banner-carousel-wrapper'
      );
      $resultsContent = $el?.querySelector('.results__banner-main-content');
      $resultsFootnote = $el?.querySelector('.results__banner-footnote');
      $carouselItemList = $el?.querySelectorAll('div.emu-carousel__item');
      $resultsAccordion = $el?.querySelector('.results__banner-accordion');
      $resultsDesktopTabsAnchor = $el?.querySelectorAll(
        '.results__banner-desktop-tabs > ul > li > a'
      );
    };

    // hides carousel view and shows the gallery view
    const showGalleryView = () => {
      $resultsContent?.classList.remove('u-hide');
      $resultsCarouselWrapper?.classList.add('u-hide');
      $resultsFootnote?.classList.add('u-hide');
    };

    // hides gallery view and shows the carousel view
    const hideGalleryView = () => {
      $resultsContent?.classList.add('u-hide');
      $resultsCarouselWrapper?.classList.remove('u-hide');
      $resultsFootnote?.classList.remove('u-hide');
    };

    // when one of the carousel item is clicked
    const carouselItemClicked = $el => {
      showGalleryView();

      // @ts-ignore
      const index = $el.getAttribute('data-emu-item-title') - 1;
      if (window.innerWidth < 768) {
        // in mobile view expanding the corresponding accordion item
        const accordionItems = $resultsAccordion.querySelectorAll(
          '.aaaem-accordion__header'
        );
        if (
          accordionItems[index].classList.contains('-ba-is-active') !== true
        ) {
          const selectedItem = accordionItems[index].querySelector(
            '.aaaem-accordion__button'
          ) as HTMLButtonElement;
          selectedItem?.click?.();
        }
      } else {
        // in desktop view selecting the corresponding tab item
        const selectedTab = $resultsDesktopTabsAnchor[
          index
        ] as HTMLAnchorElement;
        selectedTab.click();
      }
    };

    const activateNextTab = () => {
      const totalTabs = $resultsDesktopTabsAnchor.length;
      let currentTabIndex = 0;
      $resultsDesktopTabsAnchor.forEach((item, index) => {
        const isSelected = item.getAttribute('aria-selected');
        if (isSelected === 'true') {
          currentTabIndex = index;
        }
      });
      let nextTabIndex = currentTabIndex + 1;
      nextTabIndex = nextTabIndex % totalTabs;
      const nextTab = $resultsDesktopTabsAnchor[
        nextTabIndex
      ] as HTMLAnchorElement;
      nextTab.click();
    };

    // resets all the treatment tabs to the first tab
    const resetNestedTabItem = () => {
      // timer so that the accordion close event is complete before doing this action
      setTimeout(() => {
        const treatmentTabs = document.querySelectorAll(
          '.aaaem-tabs--treatment'
        );
        treatmentTabs.forEach(tab => {
          const firstTab = tab.querySelector(
            '.aaaem-tabs__tab > a'
          ) as HTMLAnchorElement;
          firstTab?.click?.();
        });
      }, 400);
    };

    const appendEvents = () => {
      window.Bus.on('emu-button:click', ({ id }) => {
        if (id === 'results-close-btn') {
          hideGalleryView();
        }

        // when next button is clicked, activate the corresponding tab item
        if (id === 'results-next-btn') {
          activateNextTab();
        }
      });

      // when the mobile accordion is toggled, reset the nested tabs to show the first tab active
      window.Bus.on('emu-accordion:toggle', ({ id }) => {
        if (id === 'results-mobile-accordion') {
          resetNestedTabItem();
        }
      });

      // when the desktop gallery's model tab is toggled, reset the nested tabs to show the first tab active
      window.Bus.on('emu-tabs:tabClick', ({ id }) => {
        if (id === 'results-desktop-tabs') {
          resetNestedTabItem();
        }
      });

      // when view gallery button is clicked, showing the gallery and hiding the carousel
      $viewGallery?.addEventListener('click', e => {
        e.preventDefault();
        showGalleryView();
      });

      // when carousel item is clicked, showing the corresponding gallery view
      $carouselItemList?.forEach($el => {
        $el.addEventListener('click', e => {
          e.preventDefault();
          carouselItemClicked($el);
        });
      });
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const $resultsBanner = document.querySelector('.results__banner');
    if ($resultsBanner) {
      handleResultsBanner($resultsBanner);
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
