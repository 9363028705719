(() => {
  const handleFapSearchBtn = ($el: HTMLElement) => {
    let $fapSearchBtn: HTMLButtonElement;
    let $zipCodeInputElement: HTMLInputElement;

    // Code lifted from live site. Alerts are a part of it
    const validateZipCode = zipCode => {
      const valid = '0123456789-';
      let hyphenCount = 0;

      if (zipCode.length != 5 && zipCode.length != 10) {
        alert('Please enter your 5 digit zip code.');
        return false;
      }

      for (let i = 0; i < zipCode.length; i++) {
        let temp = '' + zipCode.substring(i, i + 1);
        if (temp === '-') hyphenCount++;

        if (valid.indexOf(temp) < 0) {
          alert('Invalid characters in your zip code.  Please try again.');
          return false;
        }

        if (
          hyphenCount > 1 ||
          (zipCode.length === 10 && '' + zipCode.charAt(5) !== '-')
        ) {
          alert(
            "The hyphen character should be used with a properly formatted 5 digit+four zip code, like '12345-6789'.   Please try again."
          );
          return false;
        }
      }

      return true;
    };

    const appendEvents = () => {
      $fapSearchBtn?.addEventListener('click', e => {
        e.preventDefault();
        const zipCodeValue = $zipCodeInputElement?.value;
        const brandParams =
          localStorage.getItem('brand_ast_marketing_params') != undefined
            ? localStorage.getItem('brand_ast_marketing_params')
            : '';

        if (validateZipCode(zipCodeValue)) {
          var url =
            'https://www.alle.com/search?location=' +
            zipCodeValue +
            '&brand=latisse';
          if (brandParams) {
            url += '&' + brandParams;
          }
          window.open(url, '_blank');
          return false;
        } else {
          $zipCodeInputElement?.focus();
        }
      });
    };

    const initVariables = () => {
      $fapSearchBtn = $el?.querySelector('#form-submit') as HTMLButtonElement;
      $zipCodeInputElement = $el?.querySelector('#zip') as HTMLInputElement;
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const $fap = document.querySelectorAll(
      '.fap__container'
    ) as NodeListOf<HTMLElement>;
    $fap.forEach($el => {
      handleFapSearchBtn($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
